@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900: #0a001b;
    --black_900_01: #000000;
    --gray_100: #f6f6f6;
    --gray_300: #dddddd;
    --gray_700: #626262;
    --gray_700_47: #5c535347;
    --gray_800_47: #403b3b47;
    --orange_800: #db6900;
    --white_a700: #ffffff;

    /*------Border radius variables------*/
    --radius-xs: 10px;
  }
  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    appearance: none;
    background-color: transparent;
    border-color: unset;
    border-width: 0;
    border-radius: unset;
    padding: unset;
    font-size: unset;
    line-height: unset;
  }

  /* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="search"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: transparent;
    border-color: transparent;
  }
  [type="radio"]:focus,
  [type="checkbox"]:focus {
    --tw-ring-color: transparent;
  }
}
