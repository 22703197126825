/* Réinitialisation CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: inherit;
}

/* Styles globaux */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #100F30;
  font-size: 14px; /* Taille de police par défaut */
  color: #ffffff; /* Couleur de texte par défaut */
}

input {
  background-color: transparent;
  width: 100%;
  color: inherit; /* Hériter de la couleur parente */
}

input:focus {
  outline: none;
}

/* Classes utilitaires */
.p-4 {
  padding: 1rem;
}

.m-4 {
  margin: 1rem;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

/* Ajout de styles pour les boutons */
button {
  cursor: pointer;
  background-color: #0A001B;
  color: #ffffff;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #18122B;
}

/* Ajout de styles pour les liens */
a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #C85D00; /* Couleur au survol */
}