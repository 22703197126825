/* Styles pour la barre latérale (sidebar) */
.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar .menu-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar .menu-item:hover {
  background-color: #444;
}

.sidebar .menu-item.active {
  background-color: #555;
}

.sidebar .menu-item .icon {
  margin-right: 10px;
}