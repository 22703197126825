/* Styles pour l'arrière-plan principal avec l'image et le filtre */
.main-background-container {
  position: relative;
  z-index: 0;
  background-color: rgb(0,0,0); /* Le fond de la page devient la couleur finale du dégradé */
}

.main-background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px; /* Hauteur de l'image de fond */
  background: 
    linear-gradient(
      to bottom,
      rgba(255,255,255,0) 0%,
      rgb(0, 0, 0) 60%
    ),
    url('https://reachy.fr/images/fondmrbeast.jpg') no-repeat center center;
  background-size: cover; /* Pour couvrir toute la surface */
  background-position: center top;
  z-index: -2; /* Derrière le filtre noir */
}

.main-background-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px; /* Hauteur du filtre noir */
  background: rgba(0, 0, 0, 0.5); /* Filtre noir transparent */
  z-index: -1; /* Devant l'image de fond */
}

/* Styles pour les centres d'intérêt */
.interests span {
  background-color: rgba(0, 0, 0, 0.85); /* Fond noir avec transparence 15% */
  border: 2px solid #C85D00;
  color: #EB8A1A;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
}

/* Styles pour la photo de profil */
.profile-image-container img {
  width: 100%;
  height: auto;
  border-radius: 1.575rem;
  object-fit: cover;
  max-height: 300px; /* Limiter la hauteur pour éviter la déformation */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styles pour le bouton de déconnexion */
.logout-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e11d48; /* Rouge vif au survol */
}
